.header {
  display: flex;
  justify-content: space-between;
  margin: 0 10vw;
  padding: 1rem 0;
}

.menu-button {
  display: none;
}

.header-left {
  display: flex;
  align-items: center;
}

.page-links {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.page-links a {
  text-decoration: none;
  color: #9b9b9b;
  margin-left: 2rem;
}

.page-links p {
  margin: 0;
  margin-left: 2rem;
}

.header-right {
  display: flex;
  align-items: center;
  height: 36px;
}

.header-right a {
  text-decoration: none;
}

.header-right .socials {
  margin-right: 1rem;
}

.launch-button a {
  background: #ffffff;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  /* font-family: 'Euclid Circular A'; */
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #000000;
}

.launch-button a img {
  margin-left: 0.5rem;
}

.launch-button a:nth-child(2) {
  display: none;
}

.settings-icon {
  background: #343434;
  border-radius: 10px;
  padding: 0.35rem 0.75rem;
  margin-right: 1rem;
}

/* .priceFeed {
/* background-color: #343434; */
/* border-radius: 10px;
border: 1px solid #414141;
background: #000;
height: 100%;
box-sizing: border-box;
padding: 6px; */
/* }  */


@media (max-width: 910px) {
  .header {
    margin: 0 5vw;
  }

  .header .menu-button {
    display: block;
    margin-right: 0.5rem;
  }

  .header-right .socials,
  .header-left .page-links {
    display: none;
  }

  .header-small {
    background: rgba(0, 0, 0, 0.5333333333) 0% 0% no-repeat padding-box;
    box-shadow: 0.8rem 0.3rem 0.6rem rgba(0, 0, 0, 0.1607843137);
    opacity: 1;
    -webkit-backdrop-filter: blur(2.7rem);
    backdrop-filter: blur(2.7rem);
    position: fixed;
    z-index: 801;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 22.5rem;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-width: 100%;
  }

  .header-small .logo img {
    width: 6rem;
  }

  .header-small .logo {
    line-height: 2.5rem;
    display: flex;
    justify-content: space-between;
    padding: 2rem 2.5rem;
  }
  .header-small .page-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .header-small .page-links a,
  .header-small .page-links p {
    line-height: 2.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
    font-size: 1.5rem;
  }

  .header-small .socials-container {
    line-height: 2.5rem;
    padding: 1rem 2.5rem;
  }

  .header-small .socials-container .socials {
    margin-right: 1.5rem;
  }

  .header .launch-button a:nth-child(1) {
    display: none;
  }
  .header .launch-button a:nth-child(2) {
    display: none;
  }
}



@media (min-width: 1600px) {
  .header {
    margin: 0;
  }
}

.atest{
  align-self: center;
}


/* .fxdxbuy{
  display: inline-block;
  padding: 5px 10px; */
  /* border: 1px solid transparent; Add a border */
  /* transition: all 0.3s; Add a transition for a smooth hover effect */
  /* text-decoration: none;
  color: white;
  background-color: #9b9b9b;
  border-radius: 20%;
} */


/* .fxdxbuy{
    display: inline-block;
    padding: 2px 8px;
    border: 1px solid transparent;
    transition: all 0.3s;
    text-decoration: none;
    color: white;
    background: linear-gradient(95.58deg, rgba(254, 212, 4, 0.2) -2.14%, rgba(255, 154, 2, 0.2) 100%);



    border-radius: 20%;
    width: 23px;
    text-align: center;
    margin-left: 10px;
    height: 16px;
    font-size: 12px;

} */


  /* background: linear-gradient(95.58deg, #FED404 -2.14%, #FF9A02 100%); */



/* .atest {

  background-color: rgb(199, 199, 199);
  margin: 0.75rem 0;
  border-radius: 0.25rem;
  height: 1.25rem;
    margin-bottom: 1rem;
    height: 0.75rem;
    width: 100%

}
@keyframes pulse {
  50% {
      opacity: .5;
  }
}

.atest-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
} */

/* .skeleton-text {
  display: flex;
  align-items: center;
} */
/* .buycolor {
  background: var(--Posters, linear-gradient(96deg, #FED404 -2.14%, #FF9A02 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
} */

.buycolor:hover {
  font-weight: bold; 
}

/* .priceFeed img {
  padding-right: 13px;
  margin-bottom: -3px;
  width: 18px;
  height: auto;
} */




.priceFeed {
  border-radius: 13px;
  border: 1px solid #414141;
  margin-right: 2.4rem;
  padding-left: 3px;
  padding-right: 3px;
  
}

.priceComponent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.fxdxLogo {
  margin-left: 8px;
  margin-right: 5px;
  height: 25px;
  width: 25px;
}

.loader  {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 20px;
  width: 120px;
  height: 25px;
  background: linear-gradient(150deg, #605f5f 15%, #3a3a41ca 50%, #605f5f 75%);
  background-size: 200% 100%;
  animation: shimmer 2s infinite linear;
}

.priceData {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
}


@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.buyBtn {
  margin-left: 5px;
  margin-right: 8px;
  font-size: 12px;
  border-radius: 5px;
  height: 25px;
  width: 40px;
  background: linear-gradient(96deg, rgba(254, 212, 4, 0.2) -2.14%, rgba(255, 154, 2, 0.2) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.buy {
  background: var(--Posters, linear-gradient(96deg, #fed404 -2.14%, #ff9a02 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
