.Audit-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80%;
  text-align: center;
  margin: auto;
  margin-top: 4rem;
}
.Audit-section .Audit-heading {
  font-weight: 700;
  font-size: 4.5rem;
}
.Audit-section .audit-image {
  width: 80%;
  margin: auto;
}
.Audit-section .Audit-text {
  margin: 2rem auto;
  font-size: 1.5rem;
  width: 80%;
}

.Audit-section .audit-button {
  /* margin: 3rem auto; */
  padding: 0.8rem 1.5rem;
  border-radius: 0.4rem;
  font-size: 1.2rem;
  text-decoration: none;
  background: #ffffff;
  border-radius: 30px;
  font-weight: 700;
  font-size: 1rem;
  color: #000000;
  cursor: pointer;
  border: none;
  max-width: 20%;
  margin: 3rem auto 1rem;
}

@media (max-width: 768px) {
  .Audit-section .Audit-heading {
    font-weight: 700;
    font-size: 1.75rem;
  }
  .Audit-section .audit-image {
    /* margin: auto; */
    width: 100%;
  }

  .Audit-section .Audit-text {
    margin: 2rem auto;
    font-size: 0.8rem;
    width: 90%;
  }
  .Audit-section .audit-button {
    padding: 0.6rem 1.2rem;
    margin-top: 1rem;
    font-size: 1rem;
    max-width: 30%;
  }
}
